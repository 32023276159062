import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { API } from "../../../Services/api-service";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getTheme } from "../../../SharedComponents/ThemeComponent";
toast.configure();

const theme = getTheme();
const useStyles = makeStyles((theme) => ({
  addCourseButtonBox: {
    display: "flex",
    justifyContent: "center",
    padding: "4%",
  },
  save: {
    padding: "8px 30px",
    color: "white",
    width: "110px",
    height: "41px",
    fontSize: "16px",
    background: "#FF712D",
    boxShadow: "0px 1px 2px #FBE2C6",
    textTransform: "none",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#FF712D",
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "17px",
      width: "90px",
      height: "36px",
    },
  },
  cancelBtn: {
    padding: "8px 30px",
    marginRight: "5%",
    color: "#FF712D",
    width: "110px",
    height: "41px",
    background: "#FFFFFF",
    borderRadius: "6px",
    border: "1px solid #FF712D",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    [theme.breakpoints.down("xs")]: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "17px",
      width: "90px",
      height: "36px",
    },
  },
  subHeading: {
    [theme.breakpoints.up("md")]: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "19px",
    },
    [theme.breakpoints.down("xs")]: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "17px",
    },
  },
  heading: {
    [theme.breakpoints.up("xs")]: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "21px",
      lineHeight: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "21px",
    },
  },
}));

const ReattamptModal = ({
  open,
  closeModalHandler,
  previewMode,
  handleReattempt,
  handleClearAnswers,
  handleSubmitAnswers,
  exerciseId,
}) => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);

  const actionsHandler = () => {
    setLoader(true);
    API.post(`/exercises/${exerciseId}/reattempt`)
      .then((res) => {
        toast.success("Exercise saved successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Something went wrong please try again", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
        console.log("res", err);
      });
  };
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.background3,
          color: theme.palette.heading,
        },
      }}
      maxWidth="lg"
    >
      <DialogContent>
        <div
          className="ml-3 mr-3"
          style={{ margin: "2% 0 5% 0", fontSize: "20px", fontWeight: "600" }}
        >
          <div className={classes.heading} style={{ textAlign: "center" }}>
            {" "}
            Confirm Reattempt ?{" "}
          </div>
        </div>
        <div style={{ textAlign: "center" }} className={classes.subHeading}>
          Are you sure you want to Reattempt? <br /> Reattempt will clear
          answers on all the pages
        </div>
      </DialogContent>
      <div className={classes.addCourseButtonBox}>
        <DialogActions>
          <Button
            className={classes.cancelBtn}
            disabled={loader}
            onClick={() => closeModalHandler("close")}
          >
            CANCEL
          </Button>
          <Button onClick={actionsHandler} className={classes.save}>
            {loader ? "Loading..." : "CONFIRM"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ReattamptModal;
